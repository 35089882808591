import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import CopywritingImg from "../../components/images/web/copywriting"

export default function Copywriting() {
    return (
        <>
            <Layout>
                <SEO title="Copywriting" />
                <Display
                    title="Copywriting"
                    description="Let us do the writing for you! With PROS, you get seamless, error-free, high quality copywriting services that match your voice and brand."
                    prev="/web/social-media"
                    next="/web/web-development"
                    image={<CopywritingImg />}
                />
            </Layout>                
        </>
    )
}
